body {
  height: 100vh;
  background-color: #323845;
  color: #ddd;
  font: 16px/25px "Catamaran", "Arial Narrow", Arial, Helvetica, sans-serif;
}
.colorColumn {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
}
.colorRep {
  cursor: copy;
  width: 20px;
  height: 20px;
  border: solid 2px rgba(255, 255, 255, 0.5);
}
.pointer {
  cursor: pointer;
  padding: 2px;
}
.pointer:active {
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.colorWheel {
  display: flex;
}
.values {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
}
.value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0;
  margin-left: 20px;
}
.value span {
  text-align: right;
  padding-right: 10px;
  flex: 1;
  cursor: copy;
  user-select: none;
}
.value input {
  flex: 1;
  width: 6ch;
  /* color: #333333; */
  font: 16px/25px "Catamaran", Arial, Helvetica, sans-serif;
  /* border: 0px none #ffffff; */
  /* padding: 0 5px; */

  height: 20px;
  /* box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.1); */
  /* border-radius: 5px; */
}
.value.long input {
  flex: 5;
  width: 25ch;
}
.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1 {
  font-size: 40px;
  margin-bottom: 60px;
}
